"use server";

import { getRequestEvent } from "solid-js/web";

export type GiftBoxV3HomeRouteData = {
  logoUrl?: string;
};

export function getGiftBoxV3HomeRouteData$Server() {
  let logoUrl: string | undefined = getRequestEvent()?.locals.clientLogoUrl;
  return {
    logoUrl: logoUrl,
  };
}
