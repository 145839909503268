import {
  Accessor,
  createEffect,
  createSignal,
  JSX,
  Match,
  onCleanup,
  onMount,
  Setter,
  Show,
  Switch,
} from "solid-js";
import { createJob } from "~/utils/job";
import { SubmitPhoneNumberProps } from "../verification/verification";
import { ThreeDotLoader } from "~/widgets/button";
import { Spacer } from "~/widgets/spacer";
import { ButtonDark } from "../verification/components";

type SubmitOtpProps = {
  otp: string;
  otpToken: string;
};

export type CollectGiftOtpProps<T> = {
  phoneNumber: string;
  otpToken: string;
  onVerificationSuccess: (response: T) => void;
  submitOtp: (props: SubmitOtpProps) => Promise<T>;
  resendOtp: (number: string) => Promise<SubmitPhoneNumberProps>;
  buildSubtitle?: () => JSX.Element;
};

export default function CollectOtpComponen<T>(props: CollectGiftOtpProps<T>) {
  const [otp, setOtp] = createSignal<string>("");
  const [otpToken, setOtpToken] = createSignal<string>(props.otpToken);
  const [errorMessage, setErrorMessage] = createSignal<string | undefined>("");
  const [resendOtpTimeout, setResendOtpTimeout] = createSignal<number>(30);
  let resendOtpInterval: NodeJS.Timeout | undefined;
  const ac = new AbortController();

  function resetSendOtpInterval() {
    setResendOtpTimeout(30);
    resendOtpInterval = setInterval(async () => {
      setResendOtpTimeout(resendOtpTimeout() - 1);

      if (resendOtpTimeout() == 0) {
        clearInterval(resendOtpInterval);
      }
    }, 1000);
  }

  resetSendOtpInterval();

  const submitOtpJob = createJob<T>({
    errorCallback: (error) => {
      setErrorMessage(error.message);
    },
    initialJob: async () => {
      ac.abort();
      if (otp().length != 6) {
        throw new Error("Please enter a 6 digit OTP");
      }
      return props.submitOtp({
        otp: otp(),
        otpToken: otpToken(),
      });
    },
    successCallback: async (response) => {
      return props.onVerificationSuccess(response);
    },
  });

  const resendOtpJob = createJob<SubmitPhoneNumberProps>({
    errorCallback: (error) => {
      setErrorMessage(error.message);
    },
    successCallback: async (otpInfo) => {
      resetSendOtpInterval();
      setOtpToken(otpInfo.otpToken);
    },
    initialJob: async () => {
      return props.resendOtp(props.phoneNumber);
    },
  });

  let phoneInput: HTMLInputElement | undefined;
  createEffect(() => {
    phoneInput?.focus();
  });

  createEffect(() => {
    if (otp().length == 6) {
      submitOtpJob.run();
    }

    setErrorMessage(undefined);
  });

  onCleanup(() => {
    clearInterval(resendOtpInterval);
    ac.abort();
  });

  onMount(async () => {
    // await autoReadSMS(); //disable for now
  });

  async function autoReadSMS() {
    if ("OTPCredential" in window) {
      try {
        const otp = await navigator.credentials.get({
          // @ts-ignore
          otp: { transport: ["sms"] },
          signal: ac.signal,
        });
        // @ts-ignore
        const otpCode = otp.code;
        setOtp(otpCode);
        (document.activeElement as HTMLElement)?.blur();
      } catch (err: any) {}
    }
  }
  return (
    <div class="flex w-full flex-col items-center justify-start rounded-[20px] border border-basePrimaryDark bg-baseDark  p-4">
      <span class="text-medium font-semibold text-[#757575]">
        Enter OTP sent to {props.phoneNumber ?? "your phone"}
      </span>
      <div class="my-4 w-full">{buildInputField()}</div>
      <div class="text-medium leading-[140%] text-textNormal underline-offset-2">
        <Switch>
          <Match when={resendOtpTimeout() == 0}>{buildResendOtpText()}</Match>
          <Match when={resendOtpTimeout() > 0}>{buildResendOtpTimer()}</Match>
        </Switch>
      </div>
      <ButtonDark
        class="mt-4 w-full"
        onClick={() => {
          if (submitOtpJob.jobState() == "running") return;
          submitOtpJob.run();
        }}
        isLoading={submitOtpJob.jobState() == "running"}
      >
        Continue
      </ButtonDark>{" "}
    </div>
  );

  function buildResendOtpTimer() {
    return <span class="">Resend OTP in {resendOtpTimeout()}s</span>;
  }

  function buildResendOtpText() {
    return (
      <div
        onClick={() => {
          if (submitOtpJob.jobState() == "running") return;
          if (resendOtpJob.jobState() == "running") return;
          resendOtpJob.run();
        }}
      >
        <div class="flex h-[18px] items-center ">
          <Show
            when={resendOtpJob.jobState() !== "running"}
            fallback={
              <div class="flex flex-row items-center justify-center">
                <span class="mr-4 ">Resending OTP...</span>

                <ThreeDotLoader color="#fff" />
              </div>
            }
          >
            <span class="cursor-pointer ">
              Didn’t get OTP? <u>Resend OTP</u>
            </span>
          </Show>
        </div>
      </div>
    );
  }

  function buildInputField() {
    return (
      <>
        <OtpInput otp={otp} setOtp={setOtp} />
        <Spacer height={48} />
        <Show when={errorMessage()}>
          <Spacer height={8} />
          <span class="text-medium  text-errorDark">{errorMessage()}</span>
        </Show>
      </>
    );
  }

  function OtpInput(props: { otp: Accessor<string>; setOtp: Setter<string> }) {
    const [hasFocus, setHasFocus] = createSignal<boolean>(false);

    let otpInput: HTMLInputElement | undefined;
    createEffect(async () => {
      otpInput?.focus();
    });

    return (
      <div class="relative ">
        <input
          ref={otpInput}
          class="absolute z-10 h-16 w-full bg-transparent text-transparent  caret-transparent"
          // type="tel"
          inputmode="numeric"
          autocomplete="one-time-code"
          pattern="\d{6}"
          maxlength={6}
          value={props.otp()}
          placeholder=""
          onInput={(e) => props.setOtp(e.currentTarget.value)}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
        <div class=" pointer-events-none absolute z-20 flex w-full flex-row items-center justify-around space-x-[6px] lg:space-x-3">
          {[...Array(6)].map((_, index) => (
            <div
              class={`flex h-[48px]  flex-1 cursor-text items-center justify-center rounded-[12px] border border-basePrimaryMedium bg-basePrimaryDark text-baseTertiaryLight`}
              classList={{
                "border-errorDark": !!errorMessage(),
              }}
            >
              {props.otp()[index] ? (
                props.otp()[index]
              ) : index == props.otp().length && hasFocus() ? (
                <div class={`animateBlink h-3 w-0.5 bg-baseTertiaryLight`} />
              ) : (
                <div></div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
